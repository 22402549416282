import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";

// contexts
import {store} from "./redux/store";
import {Provider as ReduxProvider} from "react-redux";
import Router from "./routes";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import {ToastProvider} from "./contexts/ToastProvider";

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <ReduxProvider store={store}>
                <I18nextProvider i18n={i18n}>
                    <BrowserRouter>
                        <ToastProvider/>
                        <Router/>
                    </BrowserRouter>
                </I18nextProvider>
            </ReduxProvider>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

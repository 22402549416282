import {apiRepository, ApiRepository} from "./repository";
import {ApiResponse} from "../dto/base.dto";
import {UserProfileResponse} from "../dto/profile.dto";
import {PROFILE_API_BASE_URN} from "../utils/constants";

export class ProfileService {
    private apiRepository: ApiRepository;

    constructor(apiRepository: ApiRepository) {
        this.apiRepository = apiRepository;
    }

    async getMe(): Promise<ApiResponse<UserProfileResponse>> {
        return this.apiRepository.get<ApiResponse<UserProfileResponse>>(PROFILE_API_BASE_URN + "/me")
    }
}

export const profileService = new ProfileService(apiRepository);
import {ApiResponse, ApiStatus} from "../dto/base.dto";
import {FieldValues, Path, UseFormSetError} from "react-hook-form";
import {showToast} from "../service/toastService";

export function getApiBaseUrl(): string {
    return "https://api.kesha.chat";
}

export const showToasterFromApiResponse = (response: ApiResponse<any>) => {
    const toastTypeMapping: Record<string, 'success' | 'error' | 'info' | 'warning'> = {
        [ApiStatus.BAD_REQUEST]: 'error',
        [ApiStatus.UNAUTHORIZED]: 'error',
        [ApiStatus.NOT_FOUND]: 'error',
        [ApiStatus.PERMISSION_DENIED]: 'error',
        [ApiStatus.CREATED]: 'info',
        [ApiStatus.SUCCESS]: 'success',
    };

    const toastType = toastTypeMapping[response.status] || 'warning';

    showToast(response.message, toastType);
}

export function setFormErrorFromResponse<T extends FieldValues>(response: ApiResponse<any>, setError: UseFormSetError<T>): void {
    if (!response.errorData) {
        return;
    }
    for (let fieldError of response.errorData) {
        setError(fieldError.fieldName as Path<T>, {message: fieldError.message});
    }
}

export function redirect(): void {
    let redirectHref = localStorage.getItem("redirect");
    localStorage.removeItem("redirect");
    if (!redirectHref) {
        redirectHref = "/";
    }
    window.location.href = redirectHref;
}

import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {TokenResponse} from "../dto/auth.dto";
import {getApiBaseUrl} from "../utils/utilities";

export class ApiRepository {
    private api: AxiosInstance;
    private accessToken: string | null = null;
    private refreshToken: string | null = null;

    constructor(baseURL: string) {
        this.setTokenFromLocalStorage();
        this.api = axios.create({
            baseURL,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.api.interceptors.request.use(
            this.addBearerToken,
            (error) => Promise.reject(error)
        );

        this.api.interceptors.response.use(
            (response) => response,
            this.handleTokenExpiration
        );
    }

    private setTokenFromLocalStorage() {
        const tokenStr = localStorage.getItem("token");
        if (tokenStr) {
            const token = JSON.parse(tokenStr) as TokenResponse;
            this.accessToken = token.accessToken;
            this.refreshToken = token.refreshToken ? token.refreshToken : null;
        } else {
            this.accessToken = null;
            this.refreshToken = null;
        }
    }

    // Add Bearer token to request headers
    private addBearerToken = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
        this.setTokenFromLocalStorage();
        if (this.accessToken && config.headers) {
            config.headers.Authorization = `Bearer ${this.accessToken}`;
        }
        return config;
    };

    // Handle 401 errors (Unauthorized) and refresh token
    private handleTokenExpiration = async (error: any): Promise<AxiosResponse> => {
        if (error.response && error.response.status === 401) {
            console.log(error);
            try {
                await this.refreshTokens(); // Attempt to refresh token
                error.config.headers.Authorization = `Bearer ${this.accessToken}`;
                return this.api.request(error.config); // Retry the original request
            } catch (refreshError) {
                // Handle token refresh failure
                this.logout();
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    };

    // Method to refresh the tokens
    private async refreshTokens(): Promise<void> {
        try {
            const response = await axios.post<TokenResponse>('/auth/refresh-token', {
                refreshToken: this.refreshToken,
            });

            // Update tokens in local storage
            this.accessToken = response.data.accessToken;
            if (response.data.refreshToken) {
                this.refreshToken = response.data.refreshToken;
                localStorage.setItem('refreshToken', this.refreshToken);
            }
            localStorage.setItem('accessToken', this.accessToken);
        } catch (error) {
            throw new Error('Unable to refresh token');
        }
    }

    // Log the user out if the refresh token fails
    public logout(): void {
        this.accessToken = null;
        this.refreshToken = null;
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        if (!window.location.pathname.startsWith("/auth")) {
            localStorage.setItem('redirect', window.location.href)
        }
        window.location.href = '/auth/login';
    }

    private isAxiosError(error: any): error is AxiosError {
        return error.isAxiosError === true;
    }

    // Send GET request
    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.api.get<T>(url, config);
        return response.data;
    }

    // Send POST request
    public async post<T, R>(url: string, data: R, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this.api.post<T>(url, data, config);
            return response.data;
        } catch (e: any) {
            if (this.isAxiosError(e) && e.response) {
                return e.response.data as T;
            }
            console.error(e);

            return Promise.reject(e);
        }
    }

    // Send PUT request
    public async put<T, R>(url: string, data: R, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.api.put<T>(url, data, config);
        return response.data;
    }

    // Send DELETE request
    public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.api.delete<T>(url, config);
        return response.data;
    }

    // Method to send requests without bearer token (for public API)
    public async requestWithoutToken<T>(config: AxiosRequestConfig): Promise<T> {
        const response = await axios.request<T>(config); // axios request without auth header
        return response.data;
    }
}

export const apiRepository: ApiRepository = new ApiRepository(getApiBaseUrl());
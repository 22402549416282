import * as React from "react";
import Box from "@mui/joy/Box";

export default function Toggler(props: {
    defaultExpanded?: boolean
    children: React.ReactNode
    renderToggle: (params: {
        open: boolean
        setOpen: React.Dispatch<React.SetStateAction<boolean>>
    }) => React.ReactNode
}) {
    const {defaultExpanded = false, renderToggle, children} = props
    const [open, setOpen] = React.useState(defaultExpanded)
    return (
        <React.Fragment>
            {renderToggle({open, setOpen})}
            <Box
                sx={[
                    {
                        display: 'grid',
                        transition: '0.2s ease',
                        '& > *': {
                            overflow: 'hidden',
                        },
                    },
                    open ? {gridTemplateRows: '1fr'} : {gridTemplateRows: '0fr'},
                ]}
            >
                {children}
            </Box>
        </React.Fragment>
    )
}

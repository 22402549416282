import {lazy, Suspense} from "react"; // use to loading , loading screen until full page is load
import {Navigate, useRoutes} from "react-router-dom";

// layouts

// config
import LoadingScreen from "../components/LoadingScreen";
import MainLayout from '../layouts/MainLayout'
import AuthLayout from "../layouts/AuthLayout";

const Loadable = (Component) => (props) => {
    return (
        <Suspense fallback={<LoadingScreen/>}>
            <Component {...props} />
        </Suspense>
    );
};

export default function Router() {
    return useRoutes([
        {
            path: '/auth',
            element: <AuthLayout/>,
            children: [
                {element: <LoginPage/>, path: 'login'},
                {element: <RegisterPage/>, path: 'register'},
                {element: <ResetPasswordPage/>, path: 'reset-password'},
                {element: <NewPasswordPage/>, path: 'new-password'},
            ]
        },
        {
            path: "/",
            element: (
                <MainLayout/>
            ),
            children: [
                {path: "", element: <HomePage/>},
                {path: "chat", element: <ChatPage/>},
                {path: "settings", element: <Settings/>},
                {path: "group", element: <GroupPage/>},
                {path: "call", element: <CallPage/>},
                {path: "profile", element: <ProfilePage/>},
                {path: "404", element: <Page404/>},
                {path: "*", element: <Navigate to="/404" replace/>},
            ],
        },
        {path: "*", element: <Navigate to="/404" replace/>},
    ]);
}

const HomePage = Loadable(
    lazy(() => import("../pages/Home")),
);

const LoginPage = Loadable(
    lazy(() => import("../pages/auth/SignIn")),
);

const ChatPage = Loadable(
    lazy(() => import("../pages/dashboard/Chat")),
);

const RegisterPage = Loadable(
    lazy(() => import("../pages/auth/Register")),
);

const ResetPasswordPage = Loadable(
    lazy(() => import("../pages/auth/ResetPassword")),
);

const NewPasswordPage = Loadable(
    lazy(() => import("../pages/auth/NewPassword")),
);

const GroupPage = Loadable(
    lazy(() => import("../pages/dashboard/Group")),
);

const Settings = Loadable(
    lazy(() => import("../pages/dashboard/Settings")),
);

const CallPage = Loadable(
    lazy(() => import("../pages/dashboard/Call")),
);

const ProfilePage = Loadable(
    lazy(() => import("../pages/dashboard/Profile")),
);
const Page404 = Loadable(lazy(() => import("../pages/Page404")));

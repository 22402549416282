import { EventEmitter } from 'events';

// Create an event emitter instance
const toastEmitter = new EventEmitter();

// Toast types and interfaces
export type ToastType = 'success' | 'error' | 'info' | 'warning';

export interface ToastOptions {
    message: string;
    type: ToastType;
}

// Create a function to trigger toast events
export const showToast = (message: string, type: ToastType = 'info') => {
    toastEmitter.emit('toast', { message, type });
};

// Function to subscribe to toast events
export const subscribeToToasts = (callback: (toast: ToastOptions) => void) => {
    toastEmitter.on('toast', callback);
};

// Function to unsubscribe from toast events
export const unsubscribeFromToasts = (callback: (toast: ToastOptions) => void) => {
    toastEmitter.off('toast', callback);
};

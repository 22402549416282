import * as React from 'react'
import {useState} from 'react'
import {CssVarsProvider} from '@mui/joy/styles'
import CssBaseline from '@mui/joy/CssBaseline'
import Box from '@mui/joy/Box'
import Sidebar from '../components/Sidebar'
import Header from '../components/Header'
import {Outlet} from "react-router-dom";
import {profileService} from "../service/profile.service";
import LoadingScreen from "../components/LoadingScreen";

export default function MainLayout() {
    const [loading, setLoading] = useState<boolean>(true);
    profileService.getMe().then((response) => {
        console.log("get me");
        const user = response.data;
        localStorage.setItem("user", JSON.stringify(user));
        setLoading(false);
    });
    return (
        <>
            {(loading && <LoadingScreen/>)}
            {(!loading && <CssVarsProvider disableTransitionOnChange>
                <CssBaseline/>
                <Box sx={{display: 'flex', minHeight: '100dvh'}}>
                    <Sidebar/>
                    <Header/>
                    <Box component='main' className='MainContent' sx={{flex: 1}}>
                        <Outlet/>
                    </Box>
                </Box>
            </CssVarsProvider>)}
        </>
    )
}

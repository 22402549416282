export interface ApiResponse<T> {
    success: boolean;
    status: ApiStatus;
    data?: T;
    message: string;
    errorData?: Array<FieldError>;
}

export enum ApiStatus {
    UNAUTHORIZED = "UNAUTHORIZED",
    PERMISSION_DENIED = "PERMISSION_DENIED",
    BAD_REQUEST = "BAD_REQUEST",
    SUCCESS = "SUCCESS",
    NOT_FOUND = "NOT_FOUND",
    CREATED = "CREATED"
}

export interface FieldError {
    fieldName: string;
    message: string;
}

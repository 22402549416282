import React, {useEffect, useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {subscribeToToasts, ToastOptions, unsubscribeFromToasts} from '../service/toastService';

export const ToastProvider: React.FC = () => {
    const [toast, setToast] = useState<ToastOptions | null>(null);

    // Subscribe to toast events
    useEffect(() => {
        const handleToast = (toast: ToastOptions) => {
            setToast(toast);
        };

        subscribeToToasts(handleToast);

        return () => {
            unsubscribeFromToasts(handleToast);
        };
    }, []);

    const handleClose = () => {
        setToast(null);
    };

    return (
        <Snackbar
            open={!!toast}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
                <Alert onClose={handleClose} severity={toast?.type} variant="filled">
                    {toast?.message}
                </Alert>
        </Snackbar>
    );
};

import * as React from 'react'
import {useLocation} from 'react-router-dom';
import GlobalStyles from '@mui/joy/GlobalStyles'
import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import Chip from '@mui/joy/Chip'
import Divider from '@mui/joy/Divider'
import IconButton from '@mui/joy/IconButton'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton, {listItemButtonClasses} from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Typography from '@mui/joy/Typography'
import Sheet from '@mui/joy/Sheet'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AlignHorizontalLeftRoundedIcon from '@mui/icons-material/AlignHorizontalLeftRounded';
import ColorSchemeToggle from './ColorSchemeToggle'
import {UserProfileResponse} from "../dto/profile.dto";
import {apiRepository} from "../service/repository";
import Toggler from "./Toggler"
import {useTranslation} from "react-i18next";
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import CallReceivedRoundedIcon from '@mui/icons-material/CallReceivedRounded';
import TokenRoundedIcon from '@mui/icons-material/TokenRounded';
import GppGoodRoundedIcon from '@mui/icons-material/GppGoodRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import QuickreplyRoundedIcon from '@mui/icons-material/QuickreplyRounded';

interface SidebarElement {
    label: string;
    icon: React.ReactNode;
    href?: string;
    permission?: string;
    badge?: string;
    nestedItems?: SidebarElement[];
}

export default function Sidebar() {
    const user: UserProfileResponse = JSON.parse(localStorage.getItem("user")!) as UserProfileResponse;
    const {t} = useTranslation();
    const location = useLocation();

    const sidebarElements: SidebarElement[] = [
        {
            label: t("home"),
            icon: <HomeRoundedIcon/>,
            href: "/"
        },
        {
            label: t("chat"),
            icon: <QuestionAnswerRoundedIcon/>,
            href: "/chat/"
        },
        {
            label: t("channels"),
            icon: <DeviceHubRoundedIcon/>,
            nestedItems: [
                {
                    label: t("all_channels"),
                    icon: <AlignHorizontalLeftRoundedIcon/>,
                    href: "/channel/"
                },
                {
                    label: t("create_channel"),
                    icon: <AddRoundedIcon/>,
                    href: "/channel/create/"
                }
            ]
        },
        {
            label: t("users"),
            icon: <PeopleAltRoundedIcon/>,
            nestedItems: [
                {
                    label: t("all_users"),
                    icon: <AlignHorizontalLeftRoundedIcon/>,
                    href: "/user/"
                },
                {
                    label: t("create_user"),
                    icon: <AddRoundedIcon/>,
                    href: "/user/create/"
                },
                {
                    label: t("requested_users"),
                    icon: <CallReceivedRoundedIcon/>,
                    href: "/user?status=requested"
                },
            ]
        },
        {
            label: t("roles_and_permissions"),
            icon: <TokenRoundedIcon/>,
            nestedItems: [
                {
                    label: t("all_roles"),
                    icon: <AlignHorizontalLeftRoundedIcon/>,
                    href: "/role/"
                },
                {
                    label: t("create_role"),
                    icon: <AddRoundedIcon/>,
                    href: "/role/create/"
                },
                {
                    label: t("permissions"),
                    icon: <GppGoodRoundedIcon/>,
                    href: "/permission/"
                },
            ]
        },
        {
            label: t("message_templates"),
            icon: <TextSnippetRoundedIcon/>,
            href: "/message_template/"
        },
        {
            label: t("auto_responders"),
            icon: <QuickreplyRoundedIcon/>,
            href: "/auto-responder/"
        }
    ];

    const isSelected = (href?: string) => location.pathname === href;

    return (
        <Sheet
            className='Sidebar'
            sx={{
                position: {xs: 'fixed', md: 'sticky'},
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10000,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={theme => ({
                    ':root': {
                        '--Sidebar-width': '220px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '240px',
                        },
                    },
                })}
            />
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <IconButton variant='soft' color='primary' size='sm'>
                    <BrightnessAutoRoundedIcon/>
                </IconButton>
                <Typography level='title-lg'>Acme Co.</Typography>
                <ColorSchemeToggle sx={{ml: 'auto'}}/>
            </Box>

            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List size='sm' sx={{
                    gap: 1,
                    '--List-nestedInsetStart': '30px',
                    '--ListItem-radius': theme => theme.vars.radius.sm
                }}>
                    {sidebarElements.map((item: SidebarElement, i) => (
                        <ListItem key={i} nested={!!item.nestedItems}>
                            {item.nestedItems ? (
                                <Toggler
                                    renderToggle={({open, setOpen}) => (
                                        <ListItemButton onClick={() => setOpen(!open)}>
                                            {item.icon}
                                            <ListItemContent>
                                                <Typography level='title-sm'>{item.label}</Typography>
                                            </ListItemContent>
                                            {item.badge &&
                                                <Chip size='sm' color='primary' variant='solid'>{item.badge}</Chip>}
                                            <KeyboardArrowDownIcon
                                                sx={[
                                                    open
                                                        ? {transform: 'rotate(180deg)'}
                                                        : {transform: 'none'},
                                                ]}
                                            />
                                        </ListItemButton>
                                    )}
                                >
                                    <List sx={{gap: 0.5}}>
                                        {item.nestedItems?.map((subItem: SidebarElement, i) => (
                                            <ListItemButton
                                                key={i}
                                                selected={isSelected(subItem.href)}
                                                role='menuitem'
                                                component='a'
                                                href={subItem.href}>
                                                {subItem.icon}
                                                <ListItemContent>
                                                    <Typography level='title-sm'>{t(subItem.label)}</Typography>
                                                </ListItemContent>
                                                {subItem.badge &&
                                                    <Chip size='sm' color='primary'
                                                          variant='solid'>{subItem.badge}</Chip>}
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Toggler>
                            ) : (
                                <ListItemButton
                                    selected={isSelected(item.href)}
                                    role='menuitem'
                                    component='a'
                                    href={item.href}>
                                    {item.icon}
                                    <ListItemContent>
                                        <Typography level='title-sm'>{t(item.label)}</Typography>
                                    </ListItemContent>
                                    {item.badge && <Chip size='sm' color='primary' variant='solid'>{item.badge}</Chip>}
                                </ListItemButton>
                            )}
                        </ListItem>
                    ))}
                </List>
                <List size='sm' sx={{
                    mt: 'auto',
                    flexGrow: 0,
                    '--ListItem-radius': theme => theme.vars.radius.sm,
                    '--List-gap': '8px',
                    mb: 2
                }}>
                    <ListItem>
                        <ListItemButton>
                            <SupportRoundedIcon/>
                            Support
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton>
                            <SettingsRoundedIcon/>
                            Settings
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
            <Divider/>
            <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <Avatar variant='outlined' size='sm'
                        src='https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286'/>
                <Box sx={{minWidth: 0, flex: 1}}>
                    <Typography level='title-sm'>{user.fullName}</Typography>
                    <Typography level='body-xs'>{user.role.name}</Typography>
                </Box>
                <IconButton size='sm' variant='plain' color='neutral' onClick={() => {
                    apiRepository.logout()
                }}>
                    <LogoutRoundedIcon/>
                </IconButton>
            </Box>
        </Sheet>
    );
}
